import { Account } from '../model/account.model';
import { FRONT_DOMAIN, environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var AccountService = /** @class */ (function () {
    function AccountService(router) {
        this.router = router;
        this.accountKey = 'account';
        this.themeMode = true;
        this.detailColumnDisplayOptions = {
            project_recommend: true,
            project_search: true,
            worker_recommend: true,
            worker_search: true
        };
        this.getDetailColumnDisplayOptions();
    }
    AccountService.prototype.setAccount = function (obj, skipV2AuthDataUpdate) {
        if (skipV2AuthDataUpdate === void 0) { skipV2AuthDataUpdate = false; }
        var account = new Account(obj.headers.get('uid'), obj.headers.get('client'), obj.headers.get('access-token'), obj.body.data.email, obj.body.data.name, obj.body.data.auth, obj.body.data.id, obj.body.data.company_name, obj.body.data.user_code);
        localStorage.setItem(this.accountKey, JSON.stringify(account));
        if (!skipV2AuthDataUpdate) {
            var v2AuthData = JSON.stringify({ client: account.client, uid: account.uid });
            var expirationDate = new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000); // 有効期限: v1側のトークン有効期限に合わせて2週間で設定
            document.cookie = "v2_auth_data=" + v2AuthData + "; domain=" + FRONT_DOMAIN + "; path=/; expires=" + expirationDate.toUTCString();
        }
    };
    AccountService.prototype.getAccount = function () {
        var accountJson = JSON.parse(localStorage.getItem(this.accountKey)) || '';
        if (accountJson === '') {
            return new Account('', '', '', '', '', '', '', '', '');
        }
        else {
            return new Account(accountJson.uid, accountJson.client, accountJson.accessToken, accountJson.email, accountJson.name, accountJson.auth, accountJson.id, accountJson.company_name, accountJson.user_code);
        }
    };
    AccountService.prototype.getSTGAccount = function () {
        this.accountKey = 'sync_to_stg_account';
        var account = this.getAccount();
        this.accountKey = 'account';
        return account;
    };
    AccountService.prototype.setSTGAccount = function (obj) {
        this.accountKey = 'sync_to_stg_account';
        // skipV2AuthDataUpdate を true に設定して v2AuthData の更新をスキップ
        // syncログインの時だけv2認証情報が2度更新されるのを防ぐため
        this.setAccount(obj, true);
        this.accountKey = 'account';
    };
    AccountService.prototype.needMultipleLoginCheck = function () {
        return !environment.staging;
    };
    AccountService.prototype.isAdminUser = function () {
        return JSON.parse(localStorage.account).auth === 'admin_user';
    };
    AccountService.prototype.isAllowedV2UserCode = function () {
        // v2参照可能な対象user_code
        var allowedUserCodes = [
            'igarashi.keita.riff',
            'sales.all.pominc',
            'sales.all.jtech',
            'sales.all.egrowth',
            'sales.all.itixsystem',
            'sales.all.trustfort',
        ];
        return allowedUserCodes.includes(JSON.parse(localStorage.account).user_code);
    };
    AccountService.prototype.getUid = function () {
        return JSON.parse(localStorage.account).uid;
    };
    AccountService.prototype.getEmail = function () {
        return JSON.parse(localStorage.account).email;
    };
    AccountService.prototype.clear = function () {
        var uuid = localStorage.getItem('uuid');
        var theme = localStorage.getItem('theme') || 'light';
        localStorage.clear();
        document.cookie = "v2_auth_data={}; domain=" + FRONT_DOMAIN + "; path=/; max-age=0";
        localStorage.setItem('theme', theme);
        if (uuid !== null) {
            localStorage.setItem('uuid', uuid);
        }
        this.setDetailColumnDisplayOptions({});
    };
    AccountService.prototype.getDetailColumnDisplayOptions = function () {
        Object.assign(this.detailColumnDisplayOptions, JSON.parse(localStorage.getItem('detailColumnDisplayOptions') || '{}'));
        return this.detailColumnDisplayOptions;
    };
    AccountService.prototype.setDetailColumnDisplayOptions = function (options) {
        Object.assign(this.detailColumnDisplayOptions, options);
        localStorage.setItem('detailColumnDisplayOptions', JSON.stringify(this.detailColumnDisplayOptions));
    };
    AccountService.ngInjectableDef = i0.defineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.inject(i1.Router)); }, token: AccountService, providedIn: "root" });
    return AccountService;
}());
export { AccountService };
