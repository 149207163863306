import { Injectable } from '@angular/core';
import { Account } from '../model/account.model';
import { DetailColumnDisplayOptions } from '../model/detail-column-display-options.model';
import { FRONT_DOMAIN, environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private accountKey = 'account';
  themeMode = true;
  detailColumnDisplayOptions: DetailColumnDisplayOptions = {
    project_recommend: true,
    project_search: true,
    worker_recommend: true,
    worker_search: true
  };

  constructor(private router: Router) {
    this.getDetailColumnDisplayOptions();
  }

  setAccount(obj: any, skipV2AuthDataUpdate: boolean = false): void {
    const account = new Account(
      obj.headers.get('uid'),
      obj.headers.get('client'),
      obj.headers.get('access-token'),
      obj.body.data.email,
      obj.body.data.name,
      obj.body.data.auth,
      obj.body.data.id,
      obj.body.data.company_name,
      obj.body.data.user_code
    );
    localStorage.setItem(this.accountKey, JSON.stringify(account));

    if (!skipV2AuthDataUpdate) {
      const v2AuthData = JSON.stringify({ client: account.client, uid: account.uid });
      const expirationDate = new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000); // 有効期限: v1側のトークン有効期限に合わせて2週間で設定
      document.cookie = `v2_auth_data=${v2AuthData}; domain=${FRONT_DOMAIN}; path=/; expires=${expirationDate.toUTCString()}`;
    }
  }

  getAccount(): Account {
    const accountJson = JSON.parse(localStorage.getItem(this.accountKey)) || '';
    if (accountJson === '') {
      return new Account('', '', '', '', '', '', '', '', '');
    } else {
      return new Account(
        accountJson.uid,
        accountJson.client,
        accountJson.accessToken,
        accountJson.email,
        accountJson.name,
        accountJson.auth,
        accountJson.id,
        accountJson.company_name,
        accountJson.user_code
      );
    }
  }

  getSTGAccount(): Account {
    this.accountKey = 'sync_to_stg_account';
    const account = this.getAccount();
    this.accountKey = 'account';
    return account;
  }

  setSTGAccount(obj: any): void {
    this.accountKey = 'sync_to_stg_account';

    // skipV2AuthDataUpdate を true に設定して v2AuthData の更新をスキップ
    // syncログインの時だけv2認証情報が2度更新されるのを防ぐため
    this.setAccount(obj, true);

    this.accountKey = 'account';
  }

  needMultipleLoginCheck() {
    return !environment.staging;
  }

  isAdminUser(): boolean {
    return JSON.parse(localStorage.account).auth === 'admin_user';
  }

  isAllowedV2UserCode(): boolean {
    // v2参照可能な対象user_code
    const allowedUserCodes: string[] = [
      'igarashi.keita.riff',
      'sales.all.pominc',
      'sales.all.jtech',
      'sales.all.egrowth',
      'sales.all.itixsystem',
      'sales.all.trustfort',
    ];
    return allowedUserCodes.includes(JSON.parse(localStorage.account).user_code);
  }

  getUid(): string {
    return JSON.parse(localStorage.account).uid;
  }

  getEmail(): string {
    return JSON.parse(localStorage.account).email;
  }

  clear(): void {
    const uuid = localStorage.getItem('uuid')
    const theme = localStorage.getItem('theme') || 'light';

    localStorage.clear();
    document.cookie = `v2_auth_data={}; domain=${FRONT_DOMAIN}; path=/; max-age=0`;

    localStorage.setItem('theme', theme);
    if (uuid !== null) {
      localStorage.setItem('uuid', uuid);
    }
    this.setDetailColumnDisplayOptions({});
  }

  getDetailColumnDisplayOptions(): DetailColumnDisplayOptions {
    Object.assign(
      this.detailColumnDisplayOptions,
      JSON.parse(localStorage.getItem('detailColumnDisplayOptions') || '{}')
    );
    return this.detailColumnDisplayOptions;
  }
  setDetailColumnDisplayOptions(options: DetailColumnDisplayOptions): void {
    Object.assign(this.detailColumnDisplayOptions, options);
    localStorage.setItem(
      'detailColumnDisplayOptions',
      JSON.stringify(this.detailColumnDisplayOptions)
    );
  }
}
