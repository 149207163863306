<header class="fixed-top">
  <nav class="navbar navbar-light navbar-expand-md">
    <div class="container-fluid">
      <a class="navbar-brand" [routerLink]="'matching/projects'">
        <img class="img-fluid float-left" [src]="logoPath()" />
        <p></p>
      </a>
      <div class="collapse navbar-collapse" id="navcol-1">
        <ul class="nav navbar-nav ml-auto">
          <li>
            <app-admin-message-box></app-admin-message-box>
          </li>
          <li>
            <a target="_blank" href="https://qoala.jp/support/">
              <i container="'body'" class="fa fa-question-circle-o" aria-hidden="true"></i>
            </a>
          <li>
            <app-theme-switcher></app-theme-switcher>
          </li>
          <li ngbDropdown class="d-inline-block dropdown">
            <button class="btn btn-outline-primary" id="dropdown_user" ngbDropdownToggle>
              &nbsp;
              <i class="fa fa-user-circle account-info"></i>
              <span>{{ this.account.email }}</span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdown_user" class="dropdown-menu" role="menu">
              <a class="dropdown-item" role="presentation" [routerLink]="'/terms-of-service'">利用規約</a>
              <a class="dropdown-item" role="presentation" [routerLink]="'/privacy-policy-component'">プライパシーポリシー</a>
              <a class="dropdown-item" role="presentation" target="_blank" href="https://www.riff.co.jp/">運営会社について</a>
              <a class="dropdown-item" role="presentation" target="_blank" href="https://qoala.jp/contact/">お問い合わせ</a>
            </div>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link logout-divider" href="#">|</a>
          </li>
          <li class="nav-item" role="presentation">
            <span class="nav-link" href="login.html">
              <button class="btn btn-primary-dark border-0 text-dark logout-link" type="button" (click)="logout()">
                ログアウト
              </button>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <ul class="nav nav-tabs primary-color">
    <li class="nav-item" *ngIf="this.accountService.isAllowedV2UserCode()">
      <a class="nav-link menu_inactive" href="#" (click)="onCloudMatchClick($event, 'matching/clouds')">
        <i class="fa fa-cloud" style="margin-right:5px;"></i>クラウドマッチ
      </a>
    </li>
    <li class="nav-item" *ngIf="this.accountService.isAllowedV2UserCode()">
      <a class="nav-link menu_inactive" href="#" (click)="onCloudMatchClick($event, 'chat-threads')">
        <i class="fa  fa-commenting" style="margin-right:5px;"></i>チャット
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="isProjects ? 'menu_active' : 'menu_inactive'" [routerLink]="'matching/projects'"
        (click)="projectClick()">
        <i class="fa fa-building" style="margin-right:5px;"></i>案件一覧
        <div class="maru right_top_circle gray_circle"
          *ngIf="projectNewReceivedMailNumber | modifyNewArrivalMailNumber">
          <div class="badge-letter">
            {{ projectNewReceivedMailNumber | modifyNewArrivalMailNumber }}
          </div>
        </div>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="isWorkers ? 'menu_active' : 'menu_inactive'" [routerLink]="'matching/workers'"
        (click)="workerClick()">
        <i class="fa fa-users" style="margin-right:5px;"></i>人材一覧
        <div class="maru right_top_circle gray_circle" *ngIf="workerNewReceivedMailNumber | modifyNewArrivalMailNumber">
          <div class="badge-letter">
            {{ workerNewReceivedMailNumber | modifyNewArrivalMailNumber }}
          </div>
        </div>
      </a>
    </li>
    <li class="nav-item nav-item-reply">
      <a class="nav-link" [ngClass]="isReply ? 'menu_active' : 'menu_inactive'" [routerLink]="'replies'">
        <i class="fa fa-reply" style="margin-right:5px;"></i>返信一覧
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="isAuto ? 'menu_active' : 'menu_inactive'" [routerLink]="'matching/auto'"
        (click)="autoClick()">
        <i class="fa fa-handshake-o" style="margin-right:5px;"></i>おすすめ
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="isOffers ? 'menu_active' : 'menu_inactive'" [routerLink]="'offer'"
        (click)="offerClick()">
        <i class="fa fa-list-alt" style="margin-right:5px;"></i>提案管理
        <div class="maru right_top_circle gray_circle" *ngIf="offerNewReceivedNumber | modifyNewArrivalMailNumber">
          <div class="badge-letter">
            {{ offerNewReceivedNumber | modifyNewArrivalMailNumber }}
          </div>
        </div>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="isMailRegister ? 'menu_active' : 'menu_inactive'" [routerLink]="'mail-register'">
        <i class="fa fa-file-text-o" style="margin-right:5px;">
          <i class="fa fa-plus"></i> </i>案件人材管理
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="isReport ? 'menu_active' : 'menu_inactive'" [routerLink]="'report'">
        <i class="fa fa-bar-chart" style="margin-right:5px;"></i>レポート(β)
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="isSetting ? 'menu_active' : 'menu_inactive'" [routerLink]="'account/setting'">
        <i class="fa fa-cog" style="margin-right:5px;"></i>設定
      </a>
    <li class="nav-item" *ngIf="this.accountService.isAdminUser()">
      <a class="nav-link" [ngClass]="isRegistration ? 'menu_active' : 'menu_inactive'" [routerLink]="'registration'">
        <i class="fa fa-user-plus" style="margin-right:5px;"></i>ユーザー登録
      </a>
    </li>
    <!-- 閉じるボタン -->
    <li class="nav-exit" *ngIf="isDetailPage()">
      <a class="nav-link" (click)="windowClose()">
        <i class="fa fa-times" style="margin-right:5px;"></i>
      </a>
    </li>
  </ul>
</header>
